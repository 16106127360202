import {Component, ViewChild, OnInit, TemplateRef, EventEmitter, Output } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { ModalService } from '../../../services/modal.service';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DeParaGeneric } from '../../../model/deparageneric';
import { CadastrosService } from '../../../services/cadastros.service';

@Component({
  selector: 'app-detalhes-deparageneric',
  templateUrl: 'deparageneric-detalhes.component.html'
})   
export class DeParaGenericDetalhesComponent implements OnInit{
      
  @ViewChild('modaldeparageneric', {static: false}) public modaldeparageneric: ModalDirective;
 
  sub: boolean = false;
  _deparageneric: DeParaGeneric = new DeParaGeneric();
  @Output() loaddeparageneric = new EventEmitter();
  titulo: string = "Novo DePara Generic";
  movSubSalvar: Subscription;
  movSubAlterar: Subscription;
  clientes: any[];
  integracao: any[];
  tipoprocessamento: any[];
  ambientes: any[];
  tipocomunicacao: any[];
  formatomensagem: any[];
  tipomediador: any[];
  modelos: any[];

  movSub: Subscription;
  movSub2: Subscription;
  movSub3: Subscription;
  movSub4: Subscription;
  movSub5: Subscription;
  movSub6: Subscription;
  movSub7: Subscription;
  movSub8: Subscription;
  endpoints: any[];
  movSub9: Subscription;

  constructor(
    private modalService: ModalService,
    private toastr: ToastrService,
    private cadastrosService: CadastrosService) {
  }
  
  ngOnInit() {

    if(this.sub == false){

      this.sub = true;

      this.modalService.modalDetalhesDeParaGeneric.subscribe(
        (p: DeParaGeneric) => {
         
          if(p == null){
            this.titulo = "Novo DePara Generic"
            this._deparageneric = new DeParaGeneric();
          }else{
            this.titulo = "Editar DePara Generic" 
            this._deparageneric = p;
          }

          this.limpaModal();

          this.getClientes();
          this.getintegracaos();
          this.modaldeparageneric.show();

        }, error => {
          console.log(error.message);
        } 
      );
    } 
 
  }

  async getClientes(){
 
    this.clientes = [];

    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }

    if (!this.movSub || this.movSub.closed){
     
      this.movSub = this.cadastrosService.listar("cliente").subscribe(
        (result: any) =>{

          this.clientes =  result;
          console.log(result);
  
       },error=>{
            console.log(error);
       });

    }
   
  }

  async getintegracaos(){
 
    this.integracao = [];

    if (this.movSub2 && this.movSub2.closed == false){
      this.movSub2.unsubscribe();
    }

    if (!this.movSub2 || this.movSub2.closed){
     
      this.movSub2 = this.cadastrosService.listar("integracao").subscribe(
        (result: any) =>{

          this.integracao =  result;
          console.log(result);
  
       },error=>{
            console.log(error);
       });

    }
   

  }

  hideModal(){
    this.modaldeparageneric.hide();
    this.loaddeparageneric.emit();
  }
 
  limpaModal(){
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

  onSubmit(form: NgForm) {

    if (!form.valid) {
      return;
    }

    if(this.titulo == "Editar deparageneric"){
      this.atualizar();
    }else{
      this.salvar();
    }
      
    form.reset();
  }

  salvar(){

    let objdeparageneric = JSON.parse(JSON.stringify(this._deparageneric));

    this.cadastrosService.salvar("deparageneric", objdeparageneric).subscribe(
      (result) =>{ 
       
        this.toastr.success('DePara Generic cadastrado com sucesso!', 'Sucesso!');
        console.log(result);

        this.hideModal();
        
      },
      error=> {

        this.toastr.error(error.message, 'Erro!');
        console.log(error);

        this.hideModal();
      }
       
    );
 
 
   
  } 

  atualizar(){

    let objdeparageneric = JSON.parse(JSON.stringify(this._deparageneric));
    
    this.cadastrosService.atualizar("deparageneric", objdeparageneric, objdeparageneric.id).subscribe(
      (result) =>{ 
       
        this.toastr.success('DePara Generic atualizado com sucesso!', 'Sucesso!');
        console.log(result);

        this.hideModal();
        
      },
      error=> {

        this.toastr.error(error.message, 'Erro!');
        console.log(error);

        this.hideModal();
      }
       
    );
  }
}
