import { EventEmitter, Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class ModalService {

  modalDetalhesEndpoint = new EventEmitter<any>();
  modalDetalhesCliente = new EventEmitter<any>();
  modalDetalhesAmbiente = new EventEmitter<any>();
  modalDetalhesFormatoMensagem  = new EventEmitter<any>();
  modalDetalhesTipoInterface = new EventEmitter<any>();
  modalDetalhesIntegracao = new EventEmitter<any>();
  modalDetalhesModeloMediador = new EventEmitter<any>();
  modalDetalhesTipoComunicacao = new EventEmitter<any>();
  modalDetalhesTipoMediador = new EventEmitter<any>();
  modalDetalhesTipoProcessamento = new EventEmitter<any>();
  modalDetalhesRoteamento = new EventEmitter<any>();
  modalDetalhesLog = new EventEmitter<any>();
  modalDetalhesUsuariosMonitor = new EventEmitter<any>();
  modalDetalhesUsuariosApi = new EventEmitter<any>();
  modalDetalhesDeParaGeneric = new EventEmitter<any>();
  modalDetalhesPassosRoteamento = new EventEmitter<any>();
  
  constructor() {}

  
}
