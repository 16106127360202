
export class Cliente {

    public id: string;
    public nome: string;
    public cnpj: string;

    constructor(){
        
    }
}
