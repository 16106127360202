import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EndpointComponent } from './endpoint/endpoint.component';
import { ClienteComponent } from './cliente/cliente.component';
import { AmbienteComponent } from './ambiente/ambiente.component';
import { FormatoMensagemComponent } from './formatomensagem/formatomensagem.component';
import { TipoInterfaceComponent } from './tipointerface/tipointerface.component';
import { IntegracaoComponent } from './integracao/integracao.component';
import { ModeloMediadorComponent } from './modelomediador/modelomediador.component';
import { TipoComunicacaoComponent } from './tipocomunicacao/tipocomunicacao.component';
import { TipoProcessamentoComponent } from './tipoprocessamento/tipoprocessamento.component';
import { TipoMediadorComponent } from './tipomediador/tipomediador.component';
import { RoteamentoComponent } from './roteamento/roteamento.component';
import { LogComponent } from './log/log.component';
import { UsuariosMonitorComponent } from './usuariosmonitor/usuariosmonitor.component';
import { UsuariosApiComponent } from './usuariosapi/usuariosapi.component';
import { DeParaGenericComponent } from './deparageneric/deparageneric.component';
import { PassosRoteamentoComponent } from './passosroteamento/passosroteamento.component';

const routes: Routes = [
  {
    path: '',
    data: {
      title: 'components'
    },
    children: [     
      {
        path: 'usuariosmonitor',
        component: UsuariosMonitorComponent,
        data: {
          title: 'Usuários Monitor'
        },
      },
      {
        path: 'usuariosapi',
        component: UsuariosApiComponent,
        data: {
          title: 'Usuários Api'
        },
      },
      {
        path: 'logs',
        component: LogComponent,
        data: {
          title: 'Logs'
        },
      },
      {
        path: 'endpoint',
        component: EndpointComponent,
        data: {
          title: 'Endpoints'
        },
      },
      {
        path: 'cliente',
        component: ClienteComponent,
        data: {
          title: 'Clientes'
        }
      },
      {
        path: 'ambiente',
        component: AmbienteComponent,
        data: {
          title: 'Ambientes'
        }
      },
      {
        path: 'formatomensagem',
        component: FormatoMensagemComponent,
        data: {
          title: 'Formato de Mensagem'
        }
      },
      {
        path: 'tipointerface',
        component: TipoInterfaceComponent,
        data: {
          title: 'Tipo de Interface'
        }
      },
      {
        path: 'integracao',
        component: IntegracaoComponent,
        data: {
          title: 'Integração'
        }
      },
      {
        path: 'modelomediador',
        component: ModeloMediadorComponent,
        data: {
          title: 'Modelo Mediador'
        }
      },
      {
        path: 'tipocomunicacao',
        component: TipoComunicacaoComponent,
        data: {
          title: 'Tipo Comunicação'
        }
      },
      {
        path: 'tipomediador',
        component: TipoMediadorComponent,
        data: {
          title: 'Tipo Mediador'
        }
      },
      {
        path: 'tipoprocessamento',
        component: TipoProcessamentoComponent,
        data: {
          title: 'Tipo Processamento'
        }
      },
      {
        path: 'roteamento',
        component: RoteamentoComponent,
        data: {
          title: 'Roteamento'
        }
      },
      {
        path: 'passosroteamento',
        component: PassosRoteamentoComponent,
        data: {
          title: 'PassosRoteamento'
        }
      },
      {
        path: 'deparageneric',
        component: DeParaGenericComponent,
        data: {
          title: 'De/Para Generic'
        },
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ComponentsRoutingModule {}
