interface NavAttributes {
  [propName: string]: any;
}
interface NavWrapper {
  attributes: NavAttributes;
  element: string;
}
interface NavBadge {
  text: string;
  variant: string;
}
interface NavLabel {
  class?: string;
  variant: string;
}

export interface NavData {
  name?: string;
  url?: string;
  icon?: string;
  badge?: NavBadge;
  title?: boolean;
  children?: NavData[];
  variant?: string;
  attributes?: NavAttributes;
  divider?: boolean;
  class?: string;
  label?: NavLabel;
  wrapper?: NavWrapper;
}

export const navItems: NavData[] = [
  {
    title: true,
    name: 'Monitoramento'
  },
  {
    name: 'Painel',
    url: '/dashboard',
    icon: 'icon-screen-desktop',
    badge: {
      variant: 'info',
      text: 'Painel'
    }
  },
  {
    name: 'Logs',
    url: '/components/logs',
    icon: 'icon-info',
    badge: {
      variant: 'info',
      text: 'Logs'
    }
  }
 
 
];


export const navItemsAdmin: NavData[] = [
  {
    title: true,
    name: 'Monitoramento'
  },
  {
    name: 'Painel',
    url: '/dashboard',
    icon: 'icon-screen-desktop',
    badge: {
      variant: 'info',
      text: 'Painel'
    }
  },
  {
    name: 'Logs',
    url: '/components/logs',
    icon: 'icon-info',
    badge: {
      variant: 'info',
      text: 'Logs'
    }
  },
  {
    title: true,
    name: 'Cadastros'
  },
  {
    name: 'Usuarios Monitor',
    url: '/components/usuariosmonitor',
    icon: 'icon-people'
  },
  {
    name: 'Usuarios API',
    url: '/components/usuariosapi',
    icon: 'icon-people'
  },
  {
    name: 'Roteamento',
    url: '/components/roteamento',
    icon: 'icon-link'
  },
  {
    name: 'Passos Roteam...',
    url: '/components/passosroteamento',
    icon: 'icon-link'
  },
  {
    name: 'Endpoints',
    url: '/components/endpoint',
    icon: 'icon-link'
  },
  {
    name: 'Clientes',
    url: '/components/cliente',
    icon: 'icon-people'
  },
  {
    name: 'Ambientes',
    url: '/components/ambiente',
    icon: 'icon-people'
  },
  {
    name: 'Formato de Msg', 
    url: '/components/formatomensagem',
    icon: 'icon-people'
  },
  {
    name: 'Tipo de Interface',
    url: '/components/tipointerface',
    icon: 'icon-people'
  },
  {
    name: 'Integração',
    url: '/components/integracao',
    icon: 'icon-people'
  },
  {
    name: 'Modelo Mediador',
    url: '/components/modelomediador',
    icon: 'icon-people'
  },
  {
    name: 'Tipo de Comunicação',
    url: '/components/tipocomunicacao',
    icon: 'icon-people'
  }, 
  {
    name: 'Tipo de Mediador',
    url: '/components/tipomediador',
    icon: 'icon-people'
  }, 
  {
    name: 'Tipo de Processame...',
    url: '/components/tipoprocessamento',
    icon: 'icon-people'
  }, 
  {
    name: 'De/Para Generic',
    url: '/components/deparageneric',
    icon: 'icon-people'
  }
 
 
];

