import {Component, ViewChild, OnInit, TemplateRef, EventEmitter, Output } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { ModalService } from '../../../services/modal.service';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Ambiente } from '../../../model/ambiente';
import { CadastrosService } from '../../../services/cadastros.service';
 
@Component({
  selector: 'app-detalhes-ambiente',
  templateUrl: 'ambiente-detalhes.component.html'
})   
export class AmbienteDetalhesComponent implements OnInit{
      
  @ViewChild('modalambiente', {static: false}) public modalambiente: ModalDirective;
 
  sub: boolean = false;
  _ambiente: Ambiente = new Ambiente();
  @Output() loadambiente = new EventEmitter();
  titulo: string = "Novo Ambiente";
  movSubSalvar: Subscription;
  movSubAlterar: Subscription;
  movSub: Subscription;
  clientes: any[];

  constructor(
    private modalService: ModalService,
    private toastr: ToastrService,
    private cadastrosService: CadastrosService) {
  }
  
  ngOnInit() {

    if(this.sub == false){

      this.sub = true;

      this.modalService.modalDetalhesAmbiente.subscribe(
        (p: Ambiente) => {
         
          if(p == null){
            this.titulo = "Novo Ambiente"
            this._ambiente = new Ambiente();
          }else{
            this.titulo = "Editar Ambiente" 
            this._ambiente = p;
          }

          this.limpaModal();

          this.getClientes();
          this.modalambiente.show();

        }, error => {
          console.log(error.message);
        } 
      );
    } 
 
  }

  async getClientes(){
 
    this.clientes = [];

    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }

    if (!this.movSub || this.movSub.closed){
     
      this.movSub = this.cadastrosService.listar("cliente").subscribe(
        (result: any) =>{

          this.clientes =  result;
          console.log(result);
  
       },error=>{
            console.log(error);
       });

    }
   

  }

  hideModal(){
    this.modalambiente.hide();
    this.loadambiente.emit();
  }
 
  limpaModal(){
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

  onSubmit(form: NgForm) {

    if (!form.valid) {
      return;
    }

    if(this.titulo == "Editar Ambiente"){
      this.atualizar();
    }else{
      this.salvar();
    }
      
    form.reset();
  }

  salvar(){

    let objambiente = JSON.parse(JSON.stringify(this._ambiente));

    this.cadastrosService.salvar("ambiente", objambiente).subscribe(
      (result) =>{ 
       
        this.toastr.success('Ambiente cadastrado com sucesso!', 'Sucesso!');
        console.log(result);

        this.hideModal();
        
      },
      error=> {

        this.toastr.error(error.message, 'Erro!');
        console.log(error);

        this.hideModal();
      }
       
    );
 
 
   
  } 

  atualizar(){

    let objambiente = JSON.parse(JSON.stringify(this._ambiente));
    
    this.cadastrosService.atualizar("ambiente", objambiente, objambiente.id).subscribe(
      (result) =>{ 
       
        this.toastr.success('Ambiente atualizado com sucesso!', 'Sucesso!');
        console.log(result);

        this.hideModal();
        
      },
      error=> {

        this.toastr.error(error.message, 'Erro!');
        console.log(error);

        this.hideModal();
      }
       
    );
  }
}
