import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ModalService } from '../../services/modal.service';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { Cliente } from '../../model/cliente';
import { CadastrosService } from '../../services/cadastros.service';

@Component({ 
  selector: 'app-cliente',
  templateUrl: './cliente.component.html',
  styleUrls: ['./cliente.component.scss']
})  
export class ClienteComponent implements OnInit {
      
  movSub: Subscription;
  cliente: any[];
  fileReader: FileReader;
  fileToRead: File;

  constructor(
      private spinner: NgxSpinnerService,
      private modalService: ModalService,
      private cadastrosService: CadastrosService,
      private toastr: ToastrService,
      private confirmationDialogService: ConfirmationDialogService
  ){
    
  }

  ngOnInit() {
    this.getClientes();
  }

  async getClientes(){
 
    this.cliente = [];

    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }

    if (!this.movSub || this.movSub.closed){

      this.spinner.show();
     
      this.movSub = this.cadastrosService.listar("cliente").subscribe(
        (result: any) =>{

          this.cliente =  result;
          console.log(result);

          this.spinner.hide();
  
       },error=>{
            console.log(error);
            this.spinner.hide();
       });

    }
   

  }

  ngOnDestroy(){
    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }
  }

  novocliente(){
    this.modalService.modalDetalhesCliente.emit(null);
  }

  editar(cliente: Cliente){
    this.modalService.modalDetalhesCliente.emit(cliente);
  }
 
  excluir(cliente: Cliente){
    
    this.confirmationDialogService.confirm('Confirmar?', 'Realmente deseja excluir ?')
      .then((confirmed) =>{
          
        if(confirmed){
          this.cadastrosService.excluir("cliente", cliente.id).subscribe(
            (result) =>{ 
            
              this.toastr.success('Cliente excluído com sucesso!', 'Sucesso!');
              this.getClientes();
              console.log(result);  
               
            },
            error=> {
      
              this.toastr.error(error.message, 'Erro!');
              console.log(error);
      
            }
          );
        }
        
      })
      .catch(() => {
        console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });

  }


}
