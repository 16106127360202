import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ModalService } from '../../services/modal.service';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { TipoProcessamento } from '../../model/tipoprocessamento';
import { CadastrosService } from '../../services/cadastros.service';

@Component({ 
  selector: 'app-tipoprocessamento',
  templateUrl: './tipoprocessamento.component.html',
  styleUrls: ['./tipoprocessamento.component.scss']
})  
export class TipoProcessamentoComponent implements OnInit {
      
  movSub: Subscription;
  tipoprocessamento: any[];
  fileReader: FileReader;
  fileToRead: File;

  constructor(
      private spinner: NgxSpinnerService,
      private modalService: ModalService,
      private cadastrosService: CadastrosService,
      private toastr: ToastrService,
      private confirmationDialogService: ConfirmationDialogService
  ){
    
  }

  ngOnInit() {
    this.gettipoprocessamentos();
  }

  async gettipoprocessamentos(){
 
    this.tipoprocessamento = [];

    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }

    if (!this.movSub || this.movSub.closed){

      this.spinner.show();
     
      this.movSub = this.cadastrosService.listar("tipoprocessamento").subscribe(
        (result: any) =>{

          this.tipoprocessamento =  result;
          console.log(result);

          this.spinner.hide();
  
       },error=>{
            console.log(error);
            this.spinner.hide();
       });

    }
   

  }

  ngOnDestroy(){
    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }
  }

  novotipoprocessamento(){
    this.modalService.modalDetalhesTipoProcessamento.emit(null);
  }

  editar(tipoprocessamento: TipoProcessamento){
    this.modalService.modalDetalhesTipoProcessamento.emit(tipoprocessamento);
  }
 
  excluir(tipoprocessamento: TipoProcessamento){
    
    this.confirmationDialogService.confirm('Confirmar?', 'Realmente deseja excluir ?')
      .then((confirmed) =>{
          
        if(confirmed){
          this.cadastrosService.excluir("tipoprocessamento", tipoprocessamento.id).subscribe(
            (result) =>{ 
            
              this.toastr.success('Tipo Processamento excluído com sucesso!', 'Sucesso!');
              this.gettipoprocessamentos();
              console.log(result);  
               
            },
            error=> {
      
              this.toastr.error(error.message, 'Erro!');
              console.log(error);
      
            }
          );
        }
        
      })
      .catch(() => {
        console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });

  }


}
