import { NgModule } from '@angular/core';
import { SimNaoDirective } from './sim-nao.directive';
import { FileSelectDirective, FileDropDirective } from 'ng2-file-upload';
import { StatusDirective } from './status.directive';
import { VerticalDirective } from './vertical.directive';
import { ErroDirective } from './erro.directive';

@NgModule({
    declarations: [
        SimNaoDirective,
        FileSelectDirective, 
        FileDropDirective,
        StatusDirective,
        VerticalDirective,
        ErroDirective
    ], 

    exports: [
        SimNaoDirective,
        FileSelectDirective,
        FileDropDirective,
        StatusDirective,
        VerticalDirective,
        ErroDirective
    ]
})
export default class DirectivesModule{}