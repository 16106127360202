
export class Integracao {

    public id: string;
    public idCliente: string;
    public idTipo: string;

    constructor(){
        
    }
}
