import {Component, ViewChild, OnInit, TemplateRef, EventEmitter, Output } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { ModalService } from '../../../services/modal.service';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Roteamento } from '../../../model/roteamento';
import { CadastrosService } from '../../../services/cadastros.service';
import { ThemeService } from 'ng2-charts';
 
@Component({
  selector: 'app-detalhes-roteamento',
  templateUrl: 'roteamento-detalhes.component.html'
})   
export class RoteamentoDetalhesComponent implements OnInit{
      
  @ViewChild('modalroteamento', {static: false}) public modalroteamento: ModalDirective;
 
  sub: boolean = false;
  _roteamento: Roteamento = new Roteamento();
  @Output() loadroteamento = new EventEmitter();
  titulo: string = "Novo Roteamento";
  movSubSalvar: Subscription;
  movSubAlterar: Subscription;
  clientes: any[];
  integracao: any[];
  tipoprocessamento: any[];
  ambientes: any[];
  tipocomunicacao: any[];
  formatomensagem: any[];
  tipomediador: any[];
  modelos: any[];

  movSub: Subscription;
  movSub2: Subscription;
  movSub3: Subscription;
  movSub4: Subscription;
  movSub5: Subscription;
  movSub6: Subscription;
  movSub7: Subscription;
  movSub8: Subscription;
  endpoints: any[];
  movSub9: Subscription;

  constructor(
    private modalService: ModalService,
    private toastr: ToastrService,
    private cadastrosService: CadastrosService) {
  }
  
  ngOnInit() {

    if(this.sub == false){

      this.sub = true;

      this.modalService.modalDetalhesRoteamento.subscribe(
        (p: Roteamento) => {
         
          if(p == null){
            this.titulo = "Novo Roteamento"
            this._roteamento = new Roteamento();
          }else{
            this.titulo = "Editar Roteamento" 
            this._roteamento = p;
          }

          this.limpaModal();

          this.getClientes();
          this.getintegracaos();
          this.gettipoprocessamentos();
          this.getambientes();
          this.gettipocomunicacaos();
          this.getformatomensagems();
          this.gettipomediadors();
          this.getmodelomediadors();
          this.getendpointss();
          this.modalroteamento.show();

        }, error => {
          console.log(error.message);
        } 
      );
    } 
 
  }

  async getClientes(){
 
    this.clientes = [];

    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }

    if (!this.movSub || this.movSub.closed){
     
      this.movSub = this.cadastrosService.listar("cliente").subscribe(
        (result: any) =>{

          this.clientes =  result;
          console.log(result);
  
       },error=>{
            console.log(error);
       });

    }
   
  }

  async getintegracaos(){
 
    this.integracao = [];

    if (this.movSub2 && this.movSub2.closed == false){
      this.movSub2.unsubscribe();
    }

    if (!this.movSub2 || this.movSub2.closed){
     
      this.movSub2 = this.cadastrosService.listar("integracao").subscribe(
        (result: any) =>{

          this.integracao =  result;
          console.log(result);
  
       },error=>{
            console.log(error);
       });

    }
   

  }

  async gettipoprocessamentos(){
 
    this.tipoprocessamento = [];

    if (this.movSub3 && this.movSub3.closed == false){
      this.movSub3.unsubscribe();
    }

    if (!this.movSub3 || this.movSub3.closed){

      this.movSub3 = this.cadastrosService.listar("tipoprocessamento").subscribe(
        (result: any) =>{

          this.tipoprocessamento =  result;
          console.log(result);
  
       },error=>{
            console.log(error);
       });

    }
   

  }

  async getambientes(){
 
    this.ambientes = [];

    if (this.movSub4 && this.movSub4.closed == false){
      this.movSub4.unsubscribe();
    }

    if (!this.movSub4 || this.movSub4.closed){

      this.movSub4 = this.cadastrosService.listar("ambiente").subscribe(
        (result: any) =>{

          this.ambientes =  result;
          console.log(result);
  
       },error=>{
            console.log(error);
       });

    }
   

  }

  async getendpointss(){
 
    this.endpoints = [];

    if (this.movSub9 && this.movSub9.closed == false){
      this.movSub9.unsubscribe();
    }

    if (!this.movSub9 || this.movSub9.closed){

      this.movSub9 = this.cadastrosService.listar("endpoint").subscribe(
        (result: any) =>{

          this.endpoints =  result;
          console.log(result);
  
       },error=>{
            console.log(error);
       });

    }
   

  }


  async gettipocomunicacaos(){
 
    this.tipocomunicacao = [];

    if (this.movSub5 && this.movSub5.closed == false){
      this.movSub5.unsubscribe();
    }

    if (!this.movSub5 || this.movSub5.closed){
     
      this.movSub5 = this.cadastrosService.listar("tipocomunicacao").subscribe(
        (result: any) =>{

          this.tipocomunicacao =  result;
          console.log(result);
  
       },error=>{
            console.log(error);
       });

    }
   

  }

  async getformatomensagems(){
 
    this.formatomensagem = [];

    if (this.movSub6 && this.movSub6.closed == false){
      this.movSub6.unsubscribe();
    }

    if (!this.movSub6 || this.movSub6.closed){
     
      this.movSub6 = this.cadastrosService.listar("formatomensagem").subscribe(
        (result: any) =>{

          this.formatomensagem =  result;
          console.log(result);
  
       },error=>{
            console.log(error);
       });

    }
   

  }

  async gettipomediadors(){
 
    this.tipomediador = [];

    if (this.movSub7 && this.movSub7.closed == false){
      this.movSub7.unsubscribe();
    }

    if (!this.movSub7 || this.movSub7.closed){
     
      this.movSub7 = this.cadastrosService.listar("tipomediador").subscribe(
        (result: any) =>{

          this.tipomediador =  result;
          console.log(result);
  
       },error=>{
            console.log(error);
       });

    }
   

  }

  async getmodelomediadors(){
 
    this.modelos = [];

    if (this.movSub8 && this.movSub8.closed == false){
      this.movSub8.unsubscribe();
    }

    if (!this.movSub8 || this.movSub8.closed){
     
      this.movSub8 = this.cadastrosService.listar("modelomediador").subscribe(
        (result: any) =>{

          this.modelos =  result;
          console.log(result);
  
       },error=>{
            console.log(error);
       });

    }
   

  }


  hideModal(){
    this.modalroteamento.hide();
    this.loadroteamento.emit();
  }
 
  limpaModal(){
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

  onSubmit(form: NgForm) {

    if (!form.valid) {
      return;
    }

    if(this.titulo == "Editar roteamento"){
      this.atualizar();
    }else{
      this.salvar();
    }
      
    form.reset();
  }

  salvar(){

    let objroteamento = JSON.parse(JSON.stringify(this._roteamento));

    this.cadastrosService.salvar("roteamento", objroteamento).subscribe(
      (result) =>{ 
       
        this.toastr.success('Roteamento cadastrado com sucesso!', 'Sucesso!');
        console.log(result);

        this.hideModal();
        
      },
      error=> {

        this.toastr.error(error.message, 'Erro!');
        console.log(error);

        this.hideModal();
      }
       
    );
 
 
   
  } 

  atualizar(){

    let objroteamento = JSON.parse(JSON.stringify(this._roteamento));
    
    this.cadastrosService.atualizar("roteamento", objroteamento, objroteamento.id).subscribe(
      (result) =>{ 
       
        this.toastr.success('Roteamento atualizado com sucesso!', 'Sucesso!');
        console.log(result);

        this.hideModal();
        
      },
      error=> {

        this.toastr.error(error.message, 'Erro!');
        console.log(error);

        this.hideModal();
      }
       
    );
  }
}
