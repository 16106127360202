
export class Roteamento {

    public id: string;
	public descricao: string;
	public idCliente: string;
	public idIntegracao: string;
	public idTipoProcessamento: string; 
	public idOrigem: string; 
	public idComunicacaoOrigem: string;
	public idFormatoOrigem: string;
	public idModelo: string;
	public idTipoMediador: string;
	public mediador: string;
	public idDestino: string;
	public idComunicacaoDestino: string;
	public idFormatoDestino: string;
	public idEndpointRoteamento: string;
	public idEndpointDestino: string;

    constructor(){
        
    }
}
