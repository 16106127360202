import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { FirebaseServiceProvider } from '../providers/firebase-service';


@Injectable({
  providedIn: 'root'
})
export class ClientesService {
  
  public firestore: AngularFirestore = this.fbService.dbGlobal;

  constructor(   
    private fbService: FirebaseServiceProvider ) {

  }

  getCliente(dominio: string): Observable<any> {

    return this.firestore.collection<any>('clientes').doc(dominio).valueChanges();

  }

}
