// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  
  firebaseConfig : {
    apiKey: "AIzaSyCHYpq0SxIslUA6Q6lwWA2lX2-qyT8pXRo",
    authDomain: "monitoromnipos.firebaseapp.com",
    databaseURL: "https://monitoromnipos.firebaseio.com",
    projectId: "monitoromnipos",
    storageBucket: "monitoromnipos.appspot.com",
    messagingSenderId: "226554511940",
    appId: "1:226554511940:web:f2a0cee85f48c9297c7211",
    measurementId: "G-3N5XFMEZS0"
  },

  //APIEndpoint: 'https://omnipos.coachit.com.br:8047/api/v1/'
  APIEndpoint: 'https://devapiomnipos.coachit.com.br:8047/api/v1/'


};

