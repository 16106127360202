import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { Filtro } from '../model/filtro';

@Injectable({
  providedIn: 'root'
})
export class CadastrosService {

  private URL_API = environment.APIEndpoint.valueOf();  //Local

  constructor(   
    private _http: HttpClient
  ) {

  }

  listar(entidade: string): Observable<any> {

    return this._http.get(this.URL_API+entidade);
 
  }

  listarByFilter(entidade: string, filtro: Filtro): Observable<any> {

    return this._http.post(this.URL_API+entidade, JSON.stringify(filtro), {
      headers: new HttpHeaders().append("Content-Type","application/json")
    })

  }

  salvar(entidade: string, objeto: any){

    return this._http.post(this.URL_API+entidade, JSON.stringify(objeto), {
      headers: new HttpHeaders().append("Content-Type","application/json")
    })

  }

  atualizar(entidade: string, objeto: any, id: any){

    return this._http.put(this.URL_API+entidade+"/"+id, JSON.stringify(objeto), {
      headers: new HttpHeaders().append("Content-Type","application/json")
    })

  }

  excluir(entidade: string, id: string){

    return this._http.delete(this.URL_API+entidade+"/"+id,{
      headers: new HttpHeaders().append("Content-Type","application/json")
    })

  }

}
