import {Component, ViewChild, OnInit, TemplateRef, EventEmitter, Output } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { ModalService } from '../../../services/modal.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-detalhes-log', 
  templateUrl: 'log-detalhes.component.html'
})   
export class LogDetalhesComponent implements OnInit{
      
  @ViewChild('modallog', {static: false}) public modallog: ModalDirective;
 
  sub: boolean = false;
  log: any;
  @Output() loadlog = new EventEmitter();
 
  constructor(
    private modalService: ModalService) {
  }
  
  ngOnInit() {

    if(this.sub == false){

      this.sub = true;

      this.modalService.modalDetalhesLog.subscribe(
        (p: any) => {
         
          this.log = p;

          this.limpaModal();

          this.modallog.show();

        }, error => {
          console.log(error.message);
        } 
      );
    } 
 
  }


  hideModal(){
    this.modallog.hide();
    this.loadlog.emit();
  }
 
  limpaModal(){
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

}
