import { Component, OnDestroy, Inject, OnInit, AfterContentChecked } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { navItems, navItemsAdmin } from '../../_nav';
import { AuthService } from '../../auth/auth.service';
import { CrudService } from '../../services/crudService';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements AfterContentChecked, OnInit, OnDestroy{
 
  public navItems = navItems;
  
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement;

  usuarioLogado: any;
  mostraMenu: boolean = true;
  mostraMenuAside: boolean = false;

  constructor(
    @Inject(DOCUMENT) _document?: any, 
    private authService?: AuthService,
    private crudService?: CrudService) {

    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = _document.body.classList.contains('sidebar-minimized');
    });
    this.element = _document.body;
    this.changes.observe(<Element>this.element, {
      attributes: true,
      attributeFilter: ['class']
    });

  }

  ngOnInit() {
    this.isAdmin();
    
  }

  isAdmin(){
    let user = JSON.parse(localStorage.getItem('userMonitorOmnipos'));

    let sub = this.crudService.findByGeneric("usuarios", "id", user.id ).subscribe(res => {
      if(res.length > 0){
        if(res[0].perfil == "ADMINISTRADOR"){
          this.navItems = navItemsAdmin;
        }else{
          this.navItems = navItems;
        }
      }else{
        this.navItems = navItems;
      }
      sub.unsubscribe();
    });
  }

  ngAfterContentChecked(){
    //this.isAdmin();
  }

  ngOnDestroy(): void {

    this.changes.disconnect();
  }

  onLogout() {
    
    this.authService.logout();
  }

  

}
