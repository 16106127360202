
export class Endpoint {

    public id: string;
    public nome: string;
    public uri: string;
    public idAmbiente: string;
    public idCliente: string;
    public sistema: string;
    public monitoramento: string;
    public status: string;
	public usuario: string;
    public senha: string;
    
    constructor(){
        
    }
}
