
export class DeParaGeneric {

	public id: string;
	public idGeneric: string;
	public idCliente: string;
	public idIntegracao: string;
	public source: string;
	public target: string; 

    constructor(){
        
    }
}
