import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ModalService } from '../../services/modal.service';
import { ConfirmationDialogService } from '../../components/confirmation-dialog/confirmation-dialog.service';
import { Endpoint } from '../../model/endpoint';
import { CadastrosService } from '../../services/cadastros.service';

@Component({
  selector: 'app-endpoint',
  templateUrl: './endpoint.component.html',
  styleUrls: ['./endpoint.component.scss']
})  
export class EndpointComponent implements OnInit {
      
  movSub: Subscription;
  endpoint: any[];
  fileReader: FileReader;
  fileToRead: File;

  constructor(
      private spinner: NgxSpinnerService,
      private modalService: ModalService,
      private cadastroService: CadastrosService,
      private toastr: ToastrService,
      private confirmationDialogService: ConfirmationDialogService
  ){
    
  }

  ngOnInit() {
    this.getendpoint();
  }

  async getendpoint(){
 
    this.endpoint = [];

    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }

    if (!this.movSub || this.movSub.closed){

      this.spinner.show();
     
      this.movSub = this.cadastroService.listar("endpoint").subscribe(
        (result: any) =>{

          this.endpoint =  result;
          console.log(result);

          this.spinner.hide();
  
       },error=>{
            console.log(error);
            this.spinner.hide();
       });

    }
   

  }

  ngOnDestroy(){
    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }
  }

  novoendpoint(){
    this.modalService.modalDetalhesEndpoint.emit(null);
  }

  editar(endpoint: Endpoint){
    this.modalService.modalDetalhesEndpoint.emit(endpoint);
  }
 
  excluir(endpoint: Endpoint){
    
    this.confirmationDialogService.confirm('Confirmar?', 'Realmente deseja excluir ?')
      .then((confirmed) =>{
          
        if(confirmed){
          this.cadastroService.excluir("endpoint", endpoint.id);
            
          this.toastr.success('Endpoint excluído com sucesso!', 'Sucesso!');
          this.getendpoint();               
           
        }
        
      })
      .catch(() => {
        console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });
    
  }



}
