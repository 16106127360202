import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { CadastrosService } from '../../services/cadastros.service';
import { ModalService } from '../../services/modal.service';
import { Filtro } from '../../model/filtro';
import { compilePipeFromRender2 } from '../../../../_node_modules/@angular/compiler/src/render3/r3_pipe_compiler';

@Component({ 
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.scss']
})  
export class LogComponent implements OnInit {
      
  log: any[];
  logstatus: any[];
  tipointerface: any[]; 
  fileReader: FileReader;
  fileToRead: File;
  filtro: Filtro = new Filtro(); 
  movSub: Subscription;
  movSub1: Subscription;
  movSub2: Subscription;
  movSub3: Subscription;
  movSub4: Subscription;
  movSub5: Subscription;
  movSub6: Subscription;
  movSub7: Subscription;
  movSub8: Subscription;
  movSub9: Subscription;
  integracao: any[];
  clientes: any[]; 
  ambientes: any[];
  periodoDe = new Date(new Date().getFullYear(),  new Date().getMonth(), 1, 0, 0, 0, 0);
  periodoAte = new Date(new Date().getFullYear(),  new Date().getMonth()+1, 1, 0, 0, 0, 0);
  erros: any[];

  constructor(
      private spinner: NgxSpinnerService,
      private cadastrosService: CadastrosService,
      private modalService: ModalService
  ){
    
  }

  ngOnInit() {
    this.setErros();
    this.getClientes();
    this.getlogsstatus();
    this.getambientes();
    this.getintegracaos();
    this.getlogs();
  }

  setErros(){
    this.erros = [];
    this.erros.push({ 'id' : 'Sucesso'});
    this.erros.push({ 'id' : 'Erro'});
    this.erros.push({ 'id' : 'Todos'});
  }

  async getlogs(){
 
    this.log = [];

    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }

    if (!this.movSub || this.movSub.closed){

      this.spinner.show();
     
      this.movSub = this.cadastrosService.listarByFilter("log/filter", this.filtro).subscribe(
        (result: any) =>{

          this.log =  result;
          console.log(result);

          let idLogCore = 0;
          let cor = "#ffffbc";
          
          this.log.sort((a,b) => (a.idLogCore > b.idLogCore) ? 1 : ((b.idLogCore > a.idLogCore) ? -1 : 0));
          
          for(let item of this.log) {

            if(item.idLogCore != idLogCore){
              
              if(cor === "#cbffff"){
                item.background = "#ffffbc";
                cor = "#ffffbc";
              }else{
                item.background = "#cbffff";
                cor = "#cbffff";
              } 

            }else{
              item.background = cor;
            }
            
            idLogCore = item.idLogCore;
           
          }

          this.spinner.hide();
  
       },error=>{
            console.log(error);
            this.spinner.hide();
       });

    }
   

  }

  async getlogsstatus(){
 
    this.logstatus = [];

    if (this.movSub9 && this.movSub9.closed == false){
      this.movSub9.unsubscribe();
    }

    if (!this.movSub9 || this.movSub9.closed){

      this.spinner.show();
     
      this.movSub9 = this.cadastrosService.listar("log/status").subscribe(
        (result: any) =>{

          this.logstatus =  result;
          this.logstatus.push({ 'status' : 'Todos'});
          console.log(result);

          this.spinner.hide();
  
       },error=>{
            console.log(error);
            this.spinner.hide();
       });

    }
   

  }

  async getClientes(){
 
    this.clientes = [];

    if (this.movSub1 && this.movSub1.closed == false){
      this.movSub1.unsubscribe();
    }

    if (!this.movSub1 || this.movSub1.closed){
     
      this.movSub1 = this.cadastrosService.listar("cliente").subscribe(
        (result: any) =>{

          this.clientes =  result;
          this.clientes.push({ 'id' : 'Todos'});
          console.log(result);
  
       },error=>{
            console.log(error);
       });

    }
   
  }

  async getambientes(){
 
    this.ambientes = [];

    if (this.movSub4 && this.movSub4.closed == false){
      this.movSub4.unsubscribe();
    }

    if (!this.movSub4 || this.movSub4.closed){

      this.movSub4 = this.cadastrosService.listar("ambiente").subscribe(
        (result: any) =>{
 
          this.ambientes =  result;
          this.ambientes.push({ 'id' : 'Todos'});
          console.log(result);
  
       },error=>{
            console.log(error);
       });

    }
   

  }

  async getintegracaos(){
 
    this.integracao = [];

    if (this.movSub2 && this.movSub2.closed == false){
      this.movSub2.unsubscribe();
    }

    if (!this.movSub2 || this.movSub2.closed){
     
      this.movSub2 = this.cadastrosService.listar("integracao").subscribe(
        (result: any) =>{

          this.integracao =  result;
          this.integracao.push({ 'id' : 'Todos'});
          console.log(result);
  
       },error=>{
            console.log(error);
       });

    }
   

  }

  ngOnDestroy(){
    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }
  }

  detalhe(log: any){
    this.modalService.modalDetalhesLog.emit(log);
  }

  onChangeDateDe(e){
    console.log(e);
    this.filtro.dataBegin = e;
    this.getlogs();
  }

  onChangeDateAte(e){
    console.log(e);
    this.filtro.dataEnd = e;
    this.getlogs();
  }
}
