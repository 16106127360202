
export class UsuarioMonitor {

    public id: string;
    public email: string;
    public senha: string;
    public perfil: string;

    constructor(){
        
    }
}
