
export class Filtro {

    public id: string;
	public cliente: string;
	public idIntegration: string;
	public source: string; 
	public target: string; 
	public status: string; 
	public statusErro: string; 
	public dataBegin: string;
	public dataEnd: string;

    constructor(){
        this.statusErro = "Todos";
    }
}
