import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from './auth.service';
import { FirebaseServiceProvider } from '../providers/firebase-service';
import { ClientesService } from '../services/clientes.service';
import { Subscription } from 'rxjs';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-dashboard',
  templateUrl: '../views/login/login.component.html'
})
export class AuthComponent {

  isLoading = false;
  error: string = null;
  movSub: Subscription;
  
  constructor(
      private authService: AuthService, 
      private router: Router,
      private fbService: FirebaseServiceProvider
  ) {
     if(this.authService.autoLogin()){
        this.isLoading = true;
        this.router.navigate(['/dashboard']);
     }else{
        this.fbService.init('global');
     }
  }

  onSubmit(form: NgForm) {

    this.error = null;
    
    if (!form.valid) {
      return;
    }

    const email = form.value.email;
    const password = form.value.password;

    this.isLoading = true;

    this.authService.login(email, password)
      .then(
      (result) => {
      
          this.isLoading = false;

          if (result.user.emailVerified || true) {
            this.authService.handleAuthentication(result.user.email, result.user.uid, result.user.refreshToken, 3600);
           
            if (this.movSub && this.movSub.closed == false){
              this.movSub.unsubscribe();
            }
        
            if (!this.movSub || this.movSub.closed){
                
                  this.router.navigate(['/dashboard']);
              
            }

          } 

      },
      error => {
        console.log(error);
        this.error = this.authService.handleError(error.code);
        this.isLoading = false;
      }
    );
  
    form.reset();
  }

  ngOnDestroy(){
    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }
  }

}
