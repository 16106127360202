import {Component, ViewChild, OnInit, TemplateRef, EventEmitter, Output } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { ModalService } from '../../../services/modal.service';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Endpoint } from '../../../model/endpoint';
import { CadastrosService } from '../../../services/cadastros.service';
 
@Component({
  selector: 'app-detalhes-endpoint',
  templateUrl: 'endpoint-detalhes.component.html'
})   
export class EndpointDetalhesComponent implements OnInit{
      
  @ViewChild('modalendpoint', {static: false}) public modalendpoint: ModalDirective;
 
  sub: boolean = false;
  _endpoint: Endpoint = new Endpoint();
  @Output() loadendpoint = new EventEmitter();
  titulo: string = "Novo Endpoint";
  movSubSalvar: Subscription;
  movSubAlterar: Subscription;
  ambientes: any[];
  clientes: any[];
  movSub4: Subscription;
  movSub: Subscription;

  constructor(
    private modalService: ModalService,
    private toastr: ToastrService,
    private cadastrosService: CadastrosService) {
  }
  
  ngOnInit() {

    if(this.sub == false){

      this.sub = true;

      this.modalService.modalDetalhesEndpoint.subscribe(
        (p: Endpoint) => {
         
          if(p == null){
            this.titulo = "Novo Endpoint"
            this._endpoint = new Endpoint();
          }else{
            this.titulo = "Editar Endpoint"
            this._endpoint = p;
          }

          this.getClientes();
          this.getambientes();
          this.limpaModal();

          this.modalendpoint.show();

        }, error => {
          console.log(error.message);
        } 
      );
    } 
 
  }

  async getClientes(){
 
    this.clientes = [];

    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }

    if (!this.movSub || this.movSub.closed){
     
      this.movSub = this.cadastrosService.listar("cliente").subscribe(
        (result: any) =>{

          this.clientes =  result;
          console.log(result);
  
       },error=>{
            console.log(error);
       });

    }
   
  }

  async getambientes(){
 
    this.ambientes = [];

    if (this.movSub4 && this.movSub4.closed == false){
      this.movSub4.unsubscribe();
    }

    if (!this.movSub4 || this.movSub4.closed){

      this.movSub4 = this.cadastrosService.listar("ambiente").subscribe(
        (result: any) =>{

          this.ambientes =  result;
          console.log(result);
  
       },error=>{
            console.log(error);
       });

    }
   

  }
  
  hideModal(){
    this.modalendpoint.hide();
    this.loadendpoint.emit();
  }
 
  limpaModal(){
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

  onSubmit(form: NgForm) {

    if (!form.valid) {
      return;
    }

    if(this._endpoint.id){
      this.atualizar();
    }else{
      this.salvar();
    }
      
    form.reset();
  }

  salvar(){

    let objendpoint = JSON.parse(JSON.stringify(this._endpoint));

    this.cadastrosService.salvar("endpoint", objendpoint).subscribe(
      (result) =>{ 
       
        this.toastr.success('Endpoint cadastrado com sucesso!', 'Sucesso!');
        console.log(result);

        this.hideModal();
        
      },
      error=> {

        this.toastr.error(error.message, 'Erro!');
        console.log(error);

        this.hideModal();
      }
       
    );
 
   
  } 

  atualizar(){

    let objendpoint = JSON.parse(JSON.stringify(this._endpoint));
    
    this.cadastrosService.atualizar("endpoint", objendpoint, objendpoint.id).subscribe(
      (result) =>{ 
       
        this.toastr.success('Endpoint atualizado com sucesso!', 'Sucesso!');
        console.log(result);

        this.hideModal();
        
      },
      error=> {

        this.toastr.error(error.message, 'Erro!');
        console.log(error);

        this.hideModal();
      }
       
    );
    
  }
}
