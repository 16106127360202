import {Component, ViewChild, OnInit, TemplateRef, EventEmitter, Output } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { ModalService } from '../../../services/modal.service';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Cliente } from '../../../model/cliente';
import { CadastrosService } from '../../../services/cadastros.service';
 
@Component({
  selector: 'app-detalhes-cliente',
  templateUrl: 'cliente-detalhes.component.html'
})   
export class ClienteDetalhesComponent implements OnInit{
      
  @ViewChild('modalcliente', {static: false}) public modalcliente: ModalDirective;
 
  sub: boolean = false;
  _cliente: Cliente = new Cliente();
  @Output() loadcliente = new EventEmitter();
  titulo: string = "Novo Cliente";
  movSubSalvar: Subscription;
  movSubAlterar: Subscription;

  constructor(
    private modalService: ModalService,
    private toastr: ToastrService,
    private cadastroService: CadastrosService) {
  }
  
  ngOnInit() {

    if(this.sub == false){

      this.sub = true;

      this.modalService.modalDetalhesCliente.subscribe(
        (p: Cliente) => {
         
          if(p == null){
            this.titulo = "Novo Cliente"
            this._cliente = new Cliente();
          }else{
            this.titulo = "Editar Cliente"
            this._cliente = p;
          }

          this.limpaModal();

          this.modalcliente.show();

        }, error => {
          console.log(error.message);
        } 
      );
    } 
 
  }

  hideModal(){
    this.modalcliente.hide();
    this.loadcliente.emit();
  }
 
  limpaModal(){
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

  onSubmit(form: NgForm) {

    if (!form.valid) {
      return;
    }

    if(this.titulo == "Editar Cliente"){
      this.atualizar();
    }else{
      this.salvar();
    }
      
    form.reset();
  }

  salvar(){

    let objcliente = JSON.parse(JSON.stringify(this._cliente));

    this.cadastroService.salvar("cliente", objcliente).subscribe(
      (result) =>{ 
       
        this.toastr.success('Cliente cadastrado com sucesso!', 'Sucesso!');
        console.log(result);

        this.hideModal();
        
      },
      error=> {

        this.toastr.error(error.message, 'Erro!');
        console.log(error);

        this.hideModal();
      }
       
    );
 
 
   
  } 

  atualizar(){

    let objcliente = JSON.parse(JSON.stringify(this._cliente));
    
    this.cadastroService.atualizar("cliente", objcliente, objcliente.id).subscribe(
      (result) =>{ 
       
        this.toastr.success('Cliente atualizado com sucesso!', 'Sucesso!');
        console.log(result);

        this.hideModal();
        
      },
      error=> {

        this.toastr.error(error.message, 'Erro!');
        console.log(error);

        this.hideModal();
      }
       
    );
  }
}
