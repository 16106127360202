import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ModalService } from '../../services/modal.service';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { TipoInterface } from '../../model/tipointerface';
import { CadastrosService } from '../../services/cadastros.service';

@Component({ 
  selector: 'app-tipointerface',
  templateUrl: './tipointerface.component.html',
  styleUrls: ['./tipointerface.component.scss']
})  
export class TipoInterfaceComponent implements OnInit {
      
  movSub: Subscription;
  tipointerface: any[];
  fileReader: FileReader;
  fileToRead: File;

  constructor(
      private spinner: NgxSpinnerService,
      private modalService: ModalService,
      private cadastrosService: CadastrosService,
      private toastr: ToastrService,
      private confirmationDialogService: ConfirmationDialogService
  ){
    
  }

  ngOnInit() {
    this.gettipointerfaces();
  }

  async gettipointerfaces(){
 
    this.tipointerface = [];

    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }

    if (!this.movSub || this.movSub.closed){

      this.spinner.show();
     
      this.movSub = this.cadastrosService.listar("tipointerface").subscribe(
        (result: any) =>{

          this.tipointerface =  result;
          console.log(result);

          this.spinner.hide();
  
       },error=>{
            console.log(error);
            this.spinner.hide();
       });

    }
   

  }

  ngOnDestroy(){
    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }
  }

  novotipointerface(){
    this.modalService.modalDetalhesTipoInterface.emit(null);
  }

  editar(tipointerface: TipoInterface){
    this.modalService.modalDetalhesTipoInterface.emit(tipointerface);
  }
 
  excluir(tipointerface: TipoInterface){
    
    this.confirmationDialogService.confirm('Confirmar?', 'Realmente deseja excluir ?')
      .then((confirmed) =>{
          
        if(confirmed){
          this.cadastrosService.excluir("tipointerface", tipointerface.id).subscribe(
            (result) =>{ 
            
              this.toastr.success('Tipo Interface excluído com sucesso!', 'Sucesso!');
              this.gettipointerfaces();
              console.log(result);  
               
            },
            error=> {
      
              this.toastr.error(error.message, 'Erro!');
              console.log(error);
      
            }
          );
        }
        
      })
      .catch(() => {
        console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });

  }


}
