import {Component, ViewChild, OnInit, TemplateRef, EventEmitter, Output } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { ModalService } from '../../../services/modal.service';
import * as $ from 'jquery';
import { Subscription, Observable } from 'rxjs';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UsuarioMonitor } from '../../../model/usuariomonitor';
import { CadastrosService } from '../../../services/cadastros.service';
import { AuthResponseData, AuthService } from '../../../auth/auth.service';
import { CrudService } from '../../../services/crudService';
 
@Component({
  selector: 'app-detalhes-usuariosmonitor',
  templateUrl: 'usuariosmonitor-detalhes.component.html'
})   
export class UsuariosmonitorDetalhesComponent implements OnInit{
      
  @ViewChild('modalusuariosmonitor', {static: false}) public modalusuariosmonitor: ModalDirective;
 
  sub: boolean = false;
  _usuariosmonitor: UsuarioMonitor = new UsuarioMonitor();
  @Output() loadusuariosmonitor = new EventEmitter();
  titulo: string = "Novo Usuario Monitor";
  movSubSalvar: Subscription;
  movSubAlterar: Subscription;
  isLoading = false;
  error: string = null;
  movSub: Subscription;
  perfils: any=[];

  constructor(
    private modalService: ModalService,
    private authService: AuthService,
    private toastr: ToastrService,
    private cadastroService: CadastrosService,
    private crudService: CrudService) {
  }
  
  ngOnInit() {

    this.perfils = [];
    this.perfils.push({ "id": "MONITOR"});
    this.perfils.push({ "id": "ADMINISTRADOR"});

    if(this.sub == false){

      this.sub = true;

      this.modalService.modalDetalhesUsuariosMonitor.subscribe(
        (p: UsuarioMonitor) => {
         
          if(p == null){
            this.titulo = "Novo Usuario Monitor"
            this._usuariosmonitor = new UsuarioMonitor();
          }else{
            this.titulo = "Editar Usuario Monitor"
            this._usuariosmonitor = p;
          }

          this.limpaModal();

          this.modalusuariosmonitor.show();

        }, error => {
          console.log(error.message);
        } 
      );
    } 
 
  }

  hideModal(){
    this.modalusuariosmonitor.hide();
    this.loadusuariosmonitor.emit();
  }
 
  limpaModal(){
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

  onSubmit(form: NgForm) {

    if (!form.valid) {
      return;
    }

    if(this.titulo == "Editar Usuario Monitor"){
      this.atualizar();
    }else{
      this.salvar();
    }
      
    form.reset();
  }

  salvar(){

    let objusuariosmonitor = JSON.parse(JSON.stringify(this._usuariosmonitor));

    let authObs: Observable<AuthResponseData>;
    
    authObs = this.authService.signup(this._usuariosmonitor.email, this._usuariosmonitor.senha);

    authObs.subscribe(
      resData => {
        console.log(resData);
        this.isLoading = false;
        this.error = null;

        objusuariosmonitor.id = resData.localId;
         
        this.salvaUsuario(objusuariosmonitor);
     },
      errorMessage => {
        this.toastr.warning(errorMessage, "Atenção");
        console.log(errorMessage);
        this.error = errorMessage;
        this.isLoading = false;
      }
    );

   
  } 

  salvaUsuario(objusuariosmonitor: any){

    delete objusuariosmonitor.senha;
    this.crudService.salvar("usuarios", objusuariosmonitor);
    this.toastr.success('Usuario Monitor cadastrado com sucesso!', 'Sucesso!');
    this.hideModal();
  }

  atualizar(){

    let objusuariosmonitor = JSON.parse(JSON.stringify(this._usuariosmonitor));
    
    this.crudService.atualizar("usuarios", objusuariosmonitor);
    this.toastr.success('Usuario Monitor atualizado com sucesso!', 'Sucesso!');
    this.hideModal();
  }
}
